export enum BaseRoutes {
  PRESTAMO_NOMINA = '/app/nomina/',
}

export enum Routes {
  WELCOME = 'bienvenido',
  SOLICIT = 'solicitud',
  CONTRACT = 'contrato',
  DETAILS = 'detalles',
  NOT_AFFILIATED = 'no-asociado',
  HISTORY_CHECK = 'consulta-historial-crediticio',
  ONBOARDING = 'registro',
}

export enum StepRoutes {
  PERSONAL_INFO = '/sobre-ti',
  ADDRESS = '/domicilio',
  OCUPATION = '/ocupacion',
  NIP = '/historial',
  DOCUMENTS = '/documentacion',
  CONFIRM = '/confirmar',
  FINISH = '/finalizado',
}
