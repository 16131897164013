import { registerApplication, start } from 'single-spa';
import * as serviceWorker from './serviceWorker';
import { BaseRoutes, Routes } from './baseRoutes';
import Activity from './activityFns';

const registerApps = (): void => {
  registerApplication('app', () => import('./microfrontends/app.lifecycle'), Activity.app);
  registerApplication('mfNavbar', () => import('./microfrontends/mfNavbar/mfNavbar.lifecycle'), Activity.mfNavbar);
  registerApplication('mfStepper', () => import('./microfrontends/mfStepper/mfStepper.lifecycle'), Activity.mfStepper, {
    baseRoute: BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT,
  });
  registerApplication('mfWelcome', () => import('./microfrontends/mfWelcome/mfWelcome.lifecycle'), Activity.mfWelcome);
  registerApplication(
    'mfNotAffiliated',
    () => import('./microfrontends/mfNotAffiliated/mfNotAffiliated.lifecycle'),
    Activity.mfNotAffiliated,
  );
  registerApplication('mfPerson', () => import('./microfrontends/mfPerson/mfPerson.lifecycle'), Activity.mfPerson);
  registerApplication('mfAddress', () => import('./microfrontends/mfAddress/mfAddress.lifecycle'), Activity.mfAddress);
  registerApplication(
    'mfOcupation',
    () => import('./microfrontends/mfOcupation/mfOcupation.lifecycle'),
    Activity.mfOcupation,
  );
  registerApplication('mfPinValidation', () => import('./microfrontends/mfNipCHS/mfNipCHS.lifecycle'), Activity.mfNip);
  registerApplication(
    'mfDocuments',
    () => import('./microfrontends/mfUploadDocs/mfUploadDocs.lifecycle'),
    Activity.mfDocuments,
  );
  registerApplication(
    'mfProcessingRequest',
    () => import('./microfrontends/mfProcessingRequest/mfProcessingRequest.lifecycle'),
    Activity.mfFinish,
  );
  registerApplication(
    'mfConfirmation',
    () => import('./microfrontends/mfConfirmation/mfConfirmation.lifecycle'),
    Activity.mfConfirmation,
    {
      baseRoute: BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT,
    },
  );
  registerApplication(
    'mfContractSign',
    () => import('./microfrontends/mfContractSign/mfContractSign.lifecycle'),
    Activity.mfContractSign,
  );
  registerApplication('mfDetails', () => import('./microfrontends/mfDetails/mfDetails.lifecycle'), Activity.mfDetails);
  registerApplication(
    'mfConsultaHistorialCredito',
    () => import('./microfrontends/mfConsultaHIstorialCredito/mfConsultaHIstorialCredito.lifecycle'),
    Activity.mfConsultaHistorialCredito,
  );
  registerApplication(
    'mfOnBoarding',
    () => import('./microfrontends/mfOnBoarding/mfOnBoarding.lifecycle'),
    Activity.mfOnBoarding,
  );
};

registerApps();
start();

serviceWorker.unregister();
