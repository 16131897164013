import { BaseRoutes, Routes, StepRoutes } from './baseRoutes';
import navigateToUrl from './microfrontends/shared/utilities/navigateToUrl';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(prefix => location.href.indexOf(`${location.origin}${prefix}`) !== -1);

const mfNavbar = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.PRESTAMO_NOMINA + Routes.WELCOME,
    BaseRoutes.PRESTAMO_NOMINA + Routes.NOT_AFFILIATED,
    BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT,
    BaseRoutes.PRESTAMO_NOMINA + Routes.CONTRACT,
    BaseRoutes.PRESTAMO_NOMINA + Routes.DETAILS,
    BaseRoutes.PRESTAMO_NOMINA + Routes.HISTORY_CHECK,
    BaseRoutes.PRESTAMO_NOMINA + Routes.ONBOARDING,
  );

const mfStepper = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.PERSONAL_INFO,
    BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.ADDRESS,
    BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.OCUPATION,
    BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.NIP,
    BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.DOCUMENTS,
  );

const mfWelcome = (location: Location): boolean => prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.WELCOME);

const mfNotAffiliated = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.NOT_AFFILIATED);

const mfPerson = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.PERSONAL_INFO);

const mfAddress = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.ADDRESS);

const mfOcupation = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.OCUPATION);

const mfNip = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.NIP);

const mfDocuments = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.DOCUMENTS);

const mfFinish = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.FINISH);

const mfConfirmation = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT + StepRoutes.CONFIRM);

const mfContractSign = (location: Location): boolean => prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.CONTRACT);

const mfDetails = (location: Location): boolean => prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.DETAILS);

const mfConsultaHistorialCredito = (location: Location): boolean =>
  prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.HISTORY_CHECK);

const mfOnBoarding = (location: Location): boolean => prefix(location, BaseRoutes.PRESTAMO_NOMINA + Routes.ONBOARDING);

const app = (location: Location): boolean => {
  if (
    location.pathname === BaseRoutes.PRESTAMO_NOMINA ||
    location.pathname === BaseRoutes.PRESTAMO_NOMINA + Routes.SOLICIT ||
    location.pathname === BaseRoutes.PRESTAMO_NOMINA.substr(0, BaseRoutes.PRESTAMO_NOMINA.length - 1) ||
    location.pathname === '/'
  )
    navigateToUrl(`${BaseRoutes.PRESTAMO_NOMINA + Routes.WELCOME}` || '');
  return true;
};

export default {
  app,
  mfNavbar,
  mfStepper,
  mfWelcome,
  mfNotAffiliated,
  mfPerson,
  mfAddress,
  mfOcupation,
  mfNip,
  mfDocuments,
  mfFinish,
  mfConfirmation,
  mfContractSign,
  mfDetails,
  mfConsultaHistorialCredito,
  mfOnBoarding,
};
